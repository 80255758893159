import React from "react"

import Input from "../../UI/Input"

import styles from "./ProcessForm.module.css"

const Address = () => {
  return (
    <>
      <div className={styles.titleWrap}>
        <p>Address</p>
      </div>
      <div className={styles.inputWrap}>
        <div className={styles.inputRow}>
          <Input placeholder="Address" />
          <Input placeholder="Zip" />
        </div>
        <div className={styles.inputRow}>
          <Input placeholder="City" />
          <Input placeholder="State" />
        </div>
      </div>
    </>
  )
}

export default Address
