import React from "react"

import Input from "../../UI/Input"

import styles from "./ProcessForm.module.css"

const WorkPhone = () => {
  return (
    <>
      <div className={styles.titleWrap}>
        <p>Work Phone</p>
      </div>
      <div className={styles.inputWrap}>
        <div className={styles.inputRow}>
          <Input placeholder="Work Phone" />
        </div>
      </div>
    </>
  )
}

export default WorkPhone
