import React from "react"

import Form from "./ProcessForm"

import styles from "./GetStarted.module.css"

const GetStartedView = () => {
  return (
    <>
      <section className={styles.container}>
        <div className={styles.overlay}>
          <div className={styles.formCont}>
            <Form />
          </div>
        </div>
      </section>
      <section className={styles.section}>
        <h2>DO I MEET THE NECESSARY REQUIREMENTS?</h2>
        <p>
          Before a lender qualifies you for a loan, they may check to see if
          you:
        </p>
        <ul>
          <ol>1. Are at least 18 years old and a resident of the U.S.</ol>
          <ol>2. Have a valid bank account.</ol>
          <ol>
            3. Receive at least $800 per month through a reliable income source.
          </ol>
        </ul>
        <p>
          If you have bad credit, charge-offs, or have suffered a prior
          bankruptcy, you may still qualify for funding by requesting a loan
          through our online form.
        </p>
      </section>
    </>
  )
}

export default GetStartedView
