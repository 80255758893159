import React, { useState } from "react"

import Button from "../../UI/Button"
import ProgressBar from "../../UI/ProgressBar"
import FirstLastName from "./FirstLastName"
import Address from "./Address"
import BirthDate from "./BirthDate"
import Homeowner from "./Homeowner"
import Vehicle from "./Vehicle"
import PrimaryPhone from "./PrimaryPhone"
import Email from "./Email"
import ContactTime from "./ContactTime"
import Military from "./Military"
import LicenseOrStateID from "./LicenseOrStateID"
import IncomeSource from "./IncomeSource"

import IGetPaid from "./IGetPaid"
import MonthlyNetIncome from "./MonthlyNetIncome"
import EmployerName from "./EmployerName"
import TimeEmployed from "./TimeEmployed"
import WorkPhone from "./WorkPhone"

import styles from "./ProcessForm.module.css"

const RenderStep = step => {
  if (step === 0) return <FirstLastName />
  if (step === 1) return <Address />
  if (step === 2) return <BirthDate />
  if (step === 3) return <Vehicle />
  if (step === 4) return <Email />
  if (step === 5) return <PrimaryPhone />
  if (step === 6) return <ContactTime />
  if (step === 7) return <Military />
  if (step === 8) return <LicenseOrStateID />
  if (step === 9) return <IncomeSource />
  if (step === 10) return <IGetPaid />
  if (step === 11) return <MonthlyNetIncome />
  if (step === 12) return <TimeEmployed />
  if (step === 13) return <WorkPhone />
  if (step === 14) return <EmployerName />
}

const ProcessForm = () => {
  const [step, setStep] = useState(0)
  const [percentage, setPercentage] = useState(0)

  const handleClick = () => {
    const next = 1 + step < 14 ? 1 + step : 14
    const nextPercentage = (100 / 15) * next + 1
    setStep(next)
    setPercentage(nextPercentage)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.progressWrap}>
        <ProgressBar percentage={percentage} />
      </div>
      {RenderStep(step)}
      <Button handleClick={handleClick}>Continue</Button>
    </div>
  )
}

export default ProcessForm
