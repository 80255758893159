import React from "react"

import Layout from "../components/Layout"
import GetStarted from "../components/GetStarted"
import SEO from "../components/seo"

const GetStartedPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="Get Started" />
    <GetStarted />
  </Layout>
)

export default GetStartedPage
