import React from "react"

import styles from "./ProgressBar.module.css"

const Filler = ({ percentage }) => {
  return <div className={styles.filler} style={{ width: `${percentage}%` }} />
}

const ProgressBar = ({ percentage }) => {
  return (
    <div className={styles.progressBar}>
      <Filler percentage={percentage} />
    </div>
  )
}

export default ProgressBar
